import React from 'react'
import img1 from '../assets/aboubt-image.jpg'
import img2 from '../assets/developer.jpg'
import 'aos/dist/aos.css'
import {Link} from 'react-scroll'

export default function About() {
  return (
    <div
      id="about"
      className=" bg-slate-900 text-white max-md:p-5 font-[Poppins] text-sm overflow-hidden p-[20px] pb-20 max-md:text-xs max-xl:px-[40px]"
    >
      <div
        className="p-[20px] w-fit m-auto"
        data-aos="fade-down"
        data-aos-duration="1500"
      >
        <h1 className="font-[Parisienne] w-fit text-7xl text-center m-auto px-[2px] bg-gradient-to-r from-Color1 via-Color2 to-Color3  text-transparent bg-clip-text   max-md:text-4xl">
          About Me
        </h1>
        <h1
          id="title"
          className="font-[Rubik] w-fit text-6xl uppercase m-auto text-center bottom-8 max-md:bottom-5 max-md:text-4xl relative"
        >
          About
        </h1>
      </div>
      <div className="flex max-lg:flex-col flex-row justify-center gap-x-24 py-[30px]">
        <div
          className="bg-gradient-to-r from-Color2 to-Color3 w-fit h-fit max-lg:m-auto"
          data-aos="fade-up-right"
          data-aos-duration="2000"
        >
          <img
            src={img1}
            alt=""
            width={380}
            height={100}
            className="translate-x-4 translate-y-4 max-lg:hidden"
          />
          <img
            src={img2}
            alt=""
            width={500}
            height={400}
            className="translate-y-3 lg:hidden"
          />
        </div>
        <div
          className="max-md:py-10 w-[40%] py-[50px] max-lg:w-full"
          data-aos="fade-up-left"
          data-aos-duration="2000"
        >
          <h1 className="font-[Rubik] text-4xl leading-8 w-fit max-md:text-2xl">
            Who Am I
          </h1>
          <p className="text-base max-md:text-sm text-gray-400">
            Less is more work
          </p>
          <p className="mt-5 border-l-4 pl-3 border-l-gray-500 text-justify max-md:leading-relaxed md:leading-loose max-md:text-xs bg-gray-950 p-5 ">
            Hi there! I'm Adnane Amrouss Data Science Student from Afourer Small
            Town in Morocco, With a keen interest in cutting-edge technologies. I
            believe in the power of technology to shape the future, and I am
            committed to being at the forefront of that transformation. <br />
            Armed with a degree in computer engineering, I bring a wealth of
            knowledge and a unique perspective to every project. I'm not just
            interested in writing code I'm driven by the belief that technology
            has the power to transform lives and communities.
          </p>

          <div className="flex flex-row justify-start align-middle mt-5 ">
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-75}
              duration={600}
              href="#contact"
              id="btn1"
              className="text-center w-[150px] px-[10px] bg-gradient-to-r from-Color1 via-Color2 to-Color3 rounded-md hover:shadow-md hover:shadow-Color2 transition-all duration-700 ease-out py-[14px] max-md:px-7 uppercase"
            >
              Contact Me
            </Link>
            <div className="flex flex-col text-center max-md:mx-3 md:mx-7 justify-center ">
              <p className="text-lg leading-5 max-md:text-base">+3 Years</p>
              <p className="text-sm text-gray-500">Experience</p>
            </div>
            <div className="flex flex-col text-center justify-center rounded-md ">
              <p className="text-lg leading-5 max-md:text-base">99%</p>
              <p className="text-sm text-gray-500">Satisfaction</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
