import React from 'react'
import 'aos/dist/aos.css'

export default function Skills() {
  return (
    <div
      id="skills"
      className="skills bg-[url('./assets/space.jpg')] bg-cover h-fit relative bg-fixed text-white font-[Poppins] pb-20 text-sm overflow-hidden p-[20px] max-md:px-[5px] max-md:text-xs"
    >
      <div className="relative" data-aos="fade-down" data-aos-duration="1500">
        <h1 className="font-[Parisienne] text-7xl text-center m-auto bg-gradient-to-r from-Color1 via-Color2 to-Color3 text-transparent bg-clip-text w-fit max-md:text-4xl px-[5px] ">
          My Skills
        </h1>
        <h1 className="font-[Rubik] w-fit text-6xl uppercase m-auto text-center relative bottom-8 max-md:bottom-5 max-md:text-4xl text-white">
          Skills
        </h1>
      </div>
      <div className="px-20 max-md:px-[8px] py-10 grid grid-cols-3 gap-5 max-lg:grid-cols-2 max-md:grid-cols-1 max-md:p-5 text-white relative ">
        <div
          className="bg-slate-900 p-[20px] hover:shadow-2xl transition-all"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <h1 className="font-[Rubik] text-xl max-md:text-lg font-bold bg-gradient-to-r w-fit from-Color1 via-Color2 to-Color3 text-transparent bg-clip-text">
            Frontend
          </h1>
          <p className="text-sm max-md:text-xs text-gray-400 py-2 text-justify">
            I am enthusiastic about crafting visually appealing and intuitive
            user experiences. My goal is to blend design and functionality
            seamlessly to create websites that captivate and delight users.
          </p>
          <div className="flex flex-wrap gap-2 text-sm">
            <p className="p-[10px] bg-slate-950 rounded-md">HTML</p>
            <p className="p-[10px] bg-slate-950 rounded-md">CSS</p>
            <p className="p-[10px] bg-slate-950 rounded-md">Javascript</p>
            <p className="p-[10px] bg-slate-950 rounded-md">Ecmascript 6</p>
            <p className="p-[10px] bg-slate-950 rounded-md">Reactjs</p>
            <p className="p-[10px] bg-slate-950 rounded-md">Tailwind</p>
            <p className="p-[10px] bg-slate-950 rounded-md">Wordpress</p>
          </div>
        </div>
        <div
          className="bg-slate-900 p-[20px]"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <h1 className="font-[Rubik] text-xl max-md:text-lg font-bold bg-gradient-to-r w-fit from-Color1 via-Color2 to-Color3 text-transparent bg-clip-text">
            Backend
          </h1>
          <p className="text-sm text-gray-400 py-2 text-justify max-md:text-xs">
            I have hands-on experience with server-side frameworks like Laravel
            and Nodejs, enabling me to rapidly develop and deploy scalable
            backend solutions.
          </p>
          <div className="flex flex-wrap gap-2 text-sm">
            <p className="p-[10px] bg-slate-950 rounded-md">PHP</p>
            <p className="p-[10px] bg-slate-950 rounded-md">Laravel</p>
            <p className="p-[10px] bg-slate-950 rounded-md">MySql</p>
            <p className="p-[10px] bg-slate-950 rounded-md">Oracle</p>
            <p className="p-[10px] bg-slate-950 rounded-md">Nextjs</p>
          </div>
        </div>
        <div
          className="bg-slate-900 p-[20px]"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <h1 className="font-[Rubik] text-xl max-md:text-lg font-bold bg-gradient-to-r w-fit from-Color1 via-Color2 to-Color3 text-transparent bg-clip-text ">
            Mobile & Others
          </h1>
          <p className="text-sm text-gray-400 py-2 text-justify max-md:text-xs ">
            I am enthusiastic about crafting visually appealing and intuitive
            user experiences. My goal is to blend design and functionality
            seamlessly to create websites that captivate and delight users.
          </p>
          <div className="flex flex-wrap gap-2 text-sm">
            <p className="p-[10px] bg-slate-950 rounded-md">C</p>
            <p className="p-[10px] bg-slate-950 rounded-md">Python</p>
            <p className="p-[10px] bg-slate-950 rounded-md">Flutter</p>
            <p className="p-[10px] bg-slate-950 rounded-md">Dart</p>
            <p className="p-[10px] bg-slate-950 rounded-md">Git</p>
            <p className="p-[10px] bg-slate-950 rounded-md">Github</p>
            <p className="p-[10px] bg-slate-950 rounded-md">UML</p>
          </div>
        </div>
      </div>
    </div>
  )
}
