import React from 'react'
import img1 from '../assets/technology.jpg'
import 'aos/dist/aos.css'


export default function Service() {
  return (
    <div
      id="services"
      className="h-fit bg-slate-900 font-[Poppins] text-white z-[-2] text-sm p-[20px] max-md:px-0 overflow-hidden max-md:text-xs"
    >
      <div
        className="max-md:pb-[25px]"
        data-aos="fade-down"
        data-aos-duration="1500"
      >
        <h1 className="font-[Parisienne] w-fit text-7xl text-center m-auto text-gray-400 max-md:text-4xl pt-[10px] bg-gradient-to-r from-Color1 via-Color2 to-Color3  text-transparent bg-clip-text">
          What I Do
        </h1>
        <h1
          id="title"
          className="font-[Rubik] w-fit text-6xl uppercase m-auto text-center relative bottom-8 max-md:bottom-5 max-md:text-4xl "
        >
          Services
        </h1>
      </div>
      <div
        className="relative z-[1] "
        data-aos="fade-left"
        data-aos-duration="2000"
      >
        <div id="service1" className=" service relative text-center"></div>
      </div>

      <div className="grid grid-cols-2 gap-5 py-20 px-10 w-[75%] m-auto max-md:py-10  max-xl:grid-cols-2 max-xl:w-full max-md:grid-cols-1 z-[1] max-md:px-[15px]">
        <div
          className="flex gap-4 bg-gray-950 p-[15px] rounded-lg z-[1] hover:bg-slate-800/40 transition-all delay-100 duration-700 ease-in cursor-pointer shadow-md shadow-black/50"
          data-aos="fade-down-right"
          data-aos-duration="2000"
        >
          <div className="p-[5px]">
            <i class="fa-solid fa-globe fa-2xl"></i>
          </div>
          <div>
            <h1 className="font-[Rubik] font-bold pb-2 text-xl max-md:text-lg bg-gradient-to-r from-Color1 via-Color2 to-Color2 text-transparent bg-clip-text w-fit">
              Web Development
            </h1>
            <p className="text-gray-400 pb-[10px]">
              I have the ability to create your own website using differents
              tools and new technologies, Testing Troubleshooting problems with
              performance or user experience.
            </p>
          </div>
        </div>
        <div
          className="flex gap-4 bg-gray-950 p-[15px] rounded-lg z-[1] hover:bg-slate-800/40 transition-all duration-700 ease-out cursor-pointer shadow-md shadow-black/50"
          data-aos="fade-down-left"
          data-aos-duration="1500"
        >
          <div className="p-[5px]">
            <i class="fa-solid fa-wand-magic-sparkles fa-2xl"></i>
          </div>
          <div>
            <h1 className="font-[Rubik] font-bold pb-2 text-xl max-md:text-lg bg-gradient-to-r from-Color1 via-Color2 to-Color2 text-transparent bg-clip-text w-fit">
              Web Design
            </h1>
            <p className="text-gray-400 pb-[10px]">
              I create the layout and design of a website. In simple terms,
              using design programs to create visual elements. I can
              strategically design a site that’s intuitive and easy for visitors
              to navigate.
            </p>
          </div>
        </div>

        <div
          className="flex gap-4 bg-gray-950 p-[15px] rounded-lg z-[1] hover:bg-slate-800/40  transition-all duration-700 ease-out cursor-pointer shadow-md shadow-black/50"
          data-aos="fade-up-right"
          data-aos-duration="2000"
        >
          <div className="p-[5px]">
            <i class="fa-brands fa-wordpress fa-2xl"></i>
          </div>
          <div>
            <h1 className="font-[Rubik] font-bold pb-2 text-xl max-md:text-lg bg-gradient-to-r from-Color1 via-Color2 to-Color2 text-transparent bg-clip-text w-fit">
              E-commerce Websites
            </h1>
            <p className="text-gray-400">
              I build e-commerce websites and web applications from scratch
              often working with a variety of different technologies and
              platforms like Wordpress.
            </p>
          </div>
        </div>
        <div
          className="flex gap-4 bg-gray-950 p-[15px] rounded-lg z-[1] hover:bg-slate-800/40 transition-all duration-700 ease-out cursor-pointer shadow-md shadow-black/50"
          data-aos="fade-up-left"
          data-aos-duration="2000"
        >
          <div className="p-[5px]">
            <i class="fa-solid fa-gem fa-2xl"></i>
          </div>
          <div>
            <h1 className="font-[Rubik] font-bold pb-2 text-xl max-md:text-lg bg-gradient-to-r from-Color1 via-Color2 to-Color2 text-transparent bg-clip-text w-fit">
              Mobile Applications
            </h1>
            <p className="text-gray-400 pb-[10px]">
              As a Software developer I have some knowledge to implement mobile
              applications for smartphones, tablet devices and computers. I can
              translate code into user-friendly apps through a series of
              creations and tests.
            </p>
          </div>
        </div>
      </div>
      <div
        className="relative z-[0] "
        data-aos="fade-right"
        data-aos-duration="1500"
      >
        <div id="service2" className="service relative text-center "></div>
      </div>
    </div>
  )
}
