import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Analytics } from '@vercel/analytics/react'
import About from './pages/about';
import Home from './pages/home';
import Skills from './pages/skills';
import Service from './pages/services';
import Projects from './pages/projects';
import Contact from './pages/contact';
import Footer from './pages/footer';
import Additional from './pages/additioanl';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Home />
    <About />
    <Skills />
    <Service />
    <Projects />
    <Contact />
    <Additional />
    <Footer />
    <Analytics />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
