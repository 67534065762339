import React from 'react'
import img1 from '../assets/developer.jpg'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import pr1 from '../assets/project1.png'
import pr2 from '../assets/dash.png'
import pr3 from '../assets/pr3.jpg'
import pr4 from '../assets/pr4.png'
import 'aos/dist/aos.css'

export default function Project() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div
      id="projects"
      className="h-fit bg-gray-950  text-white font-[Poppins] text-sm  overflow-hidden p-[40px]  max-md:px-[10px]  max-md:text-xs"
    >
      <div
        className="w-[50%] m-auto text-center max-lg:w-full max-lg:p-[10px]"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <h1 className="project-title text-3xl max-md:text-2xl relative w-fit text-center m-auto font-[Rubik]">
          Latests Projects
        </h1>
        <p className="text-gray-400 py-10 ">
          These projects are more than just milestones, they embody my passion
          for my commitment to delivering solutions that matter. Dive into the
          details of each project, and feel free to reach out if you're curious
          about the technology, methodology, or inspiration behind them.
        </p>
      </div>
      <div
        className="md:p-10 w-[80%] m-auto max-md:w-full max-md:px-[20px] h-fit"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <Slider {...settings}>
          <div className="h-full rounded-md overflow-hidden shadow-md">
            <div className="h-[180px] bg-slate-900">
              <img
                className="h-full w-full object-fill object-center hover:scale-110 transition duration-700 cursor-pointer"
                src={pr1}
                alt=""
              />
            </div>
            <div className="p-[20px] bg-slate-900">
              <h3 className="text-lg font-[Rubik] font-medium">
                &#8212; UniverFood
              </h3>
              <p className="text-gray-400">
                Web Application of School Restaurant
              </p>
            </div>
          </div>
          <div className="h-full rounded-md overflow-hidden shadow-md">
            <div className="h-[180px] bg-slate-900">
              <img
                className="h-full w-full object-fill object-center hover:scale-110 transition duration-700 cursor-pointer"
                src={pr2}
                alt=""
              />
            </div>
            <div className="p-[20px] bg-slate-900">
              <h3 className="text-lg font-[Rubik] font-medium">
                &#8212; Dashboard
              </h3>
              <p className="text-gray-400">Template for E-commerce Website</p>
            </div>
          </div>
          <div className="h-full rounded-md overflow-hidden shadow-md">
            <div className="h-[180px] bg-slate-900">
              <img
                className="h-full w-full object-fill object-center hover:scale-110 transition duration-700 cursor-pointer"
                src={pr3}
                alt=""
              />
            </div>
            <div className="p-[20px] bg-slate-900">
              <h3 className="text-lg font-[Rubik] font-medium">
                &#8212; Trips Saver
              </h3>
              <p className="text-gray-400">Mobile Application</p>
            </div>
          </div>
          <div className="h-full rounded-md overflow-hidden shadow-md">
            <div className="h-[180px] bg-slate-900 ">
              <img
                className="h-full w-full object-fill object-center hover:scale-110 transition duration-700 cursor-pointer"
                src={pr4}
                alt=""
              />
            </div>
            <div className="p-[20px] bg-slate-900">
              <h3 className="text-lg font-[Rubik] font-medium">
                &#8212; Ouzoud Olive Oil
              </h3>
              <p className="text-gray-400">Website using Wordpress</p>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  )
}
