import React from 'react'

export default function Additional() {
  return (
    <div
      id="additional"
      className="skills bg-[url('./assets/space.jpg')] bg-cover h-fit bg-fixed text-white font-[Poppins] text-sm relative m-auto"
    >
      <div
        className="text-center relative flex justify-center items-center m-auto w-[fit] max-md:w-full h-[100px]"
        data-aos="fade-up"
        data-aos-anchor-placement="bottom-bottom"
        data-aos-duration="2000"
      >
        <p className="text-center font-[Rubik] text-3xl max-md:text-xl relative">
          <span className="absolute bottom-[10px] left-[-30px]">
            <i class="fa-solid fa-quote-left"></i>
          </span>{' '}
          We are born to create
          <strong> ⵣ</strong>
          <span className="absolute top-[-10px] right-[-30px]">
            <i class="fa-solid fa-quote-right"></i>
          </span>
        </p>
      </div>
    </div>
  )
}