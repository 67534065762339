import React from 'react'
import 'aos/dist/aos.css'
import EmailForm from '../component/emailForm'

export default function Contact() {
  
  return (
    <div
      id="contact"
      className="bg-gray-950  h-fit text-white font-[Poppins] p-[20px] z-[1] text-sm overflow-hidden pb-20 max-md:px-[15px] max-md:text-xs"
    >
      <div className="pb-10 z-[1] " data-aos="fade-up" data-aos-duration="1500">
        <h1 className="font-[Parisienne] w-fit text-7xl  text-center m-auto max-md:text-4xl px-[5px] bg-gradient-to-r from-Color1 via-Color2 to-Color3  text-transparent bg-clip-text">
          Contact Me
        </h1>
        <h1
          id="title"
          className="font-[Rubik] w-fit text-6xl uppercase m-auto text-center relative bottom-8 max-md:bottom-5 max-md:text-4xl  "
        >
          Contact
        </h1>
      </div>
      <div
        className="relative z-[1] xl:hidden"
        data-aos="zoom-in"
        data-aos-duration="2000"
      >
        <div id="contact1" className=" service relative text-center"></div>
      </div>

      <div
        className="grid grid-cols-2 max-lg:grid-cols-1 max-lg:w-[90%] w-[70%] max-md:w-full m-auto bg-slate-900 z-[1] relative "
        data-aos="zoom-in"
        data-aos-duration="2000"
      >
        <div className="py-20 px-10 max-md:px-5 max-md:py-10 ">
          <h1 className="contact-title text-3xl max-md:text-2xl relative w-fit font-[Rubik]">
            Get In Touch
          </h1>
          <p className="text-gray-400 pt-10 pb-5 text-justify leading-normal">
            Feel free to reach out! I'm always open to new opportunities,
            collaborations, or just a friendly chat. You can contact me through
            the provided form or connect with me on your preferred social media
            platforms. Looking forward to hearing from you!
            <br />
            <span className="">Your success is my priority.</span>
          </p>
          <h3 className="mb-[10px] text-xl font-[Parisienne] text-center">
            Find Me On Social Networks
          </h3>
          <ul
            className="flex gap-5 p-[10px] justify-center items-center 
            bg-gray-950 w-fit m-auto max-md:text-sm"
          >
            <li className="">
              <a
                href="https://www.facebook.com/profile.php?id=100013120950245"
                target="_blank"
                className="p-[2px] bg-gradient-to-r from-Color2 to-Color3 text-transparent bg-clip-text hover:text-gray-300 duration-700"
                rel="noreferrer"
              >
                <i class="fa-brands fa-facebook fa-xl"></i>
              </a>
            </li>

            <li>
              <a
                href="https://www.instagram.com/adnan.amrouss/"
                target="_blank"
                className="p-[2px] bg-gradient-to-r from-Color2 to-Color3 text-transparent bg-clip-text hover:text-gray-300 duration-700 "
                rel="noreferrer"
              >
                <i class="fa-brands fa-instagram fa-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/adnane-amrouss-545687243/"
                target="_blank"
                className="p-[2px] bg-gradient-to-r from-Color2 to-Color3 text-transparent bg-clip-text  hover:text-gray-300 duration-700"
                rel="noreferrer"
              >
                <i class="fa-brands fa-linkedin-in fa-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="mailto:medamrouss14@gmail.com"
                target="_blank"
                className="p-[2px] bg-gradient-to-r from-Color2 to-Color3 text-transparent bg-clip-text hover:text-gray-300 duration-700"
                rel="noreferrer "
              >
                <i class="fa-solid fa-envelope fa-xl"></i>
              </a>
            </li>
          </ul>
        </div>
        <EmailForm />
      </div>
    </div>
  )
}