import React from 'react'
import logo from '../assets/Adnane2-removebg-preview.png'
import 'aos/dist/aos.css'
import { Link } from 'react-scroll'

export default function Footer() {
  const currentYear = new Date().getFullYear()

  return (
    <div className="bg-slate-900  text-white font-[Poppins] text-sm">
      <div className="grid grid-cols-4 w-[80%] max-lg:w-full max-lg:grid-cols-2 max-lg:px-10  m-auto md:py-[50px] max-md:py-[20px] max-md:px-[30px]  md:gap-10 max-md:gap-5 max-md:grid-cols-1 max-md:w-full">
        <div
          className="max-md:h-fit w-fit my-auto max-md:hidden"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="2000"
        >
          <img className="" src={logo} alt="" width={100} height={100} />
          <p className="text-gray-400 border-t-2 border-gray-700 pt-[10px] hover:text-white duration-700 cursor-pointer">
            <i class="fa-sharp fa-solid fa-location-dot"></i>
            <span className="pl-[5px]">Morocco, Afourer-Azilal</span>
          </p>
        </div>
        <div className="">
          <h3 className="footer-title mb-[10px] relative w-fit font-[Rubik] text-xl leading-loose py-[10px]">
            Quick Links
          </h3>
          <ul
            className=""
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="2000"
          >
            <li className="text-gray-400 leading-7 ">
              <Link
                to="home"
                href="#home"
                spy={true}
                smooth={true}
                offset={-75}
                duration={600}
                className="hover:text-white duration-700 hover:pl-1"
              >
                Home
              </Link>
            </li>
            <li className="text-gray-400 leading-7 ">
              <Link
                href="#about"
                to="about"
                spy={true}
                smooth={true}
                offset={-75}
                className="hover:text-white duration-700 hover:pl-1"
              >
                About
              </Link>
            </li>
            <li className="text-gray-400 leading-7 ">
              <Link
                href="#skills"
                to="skills"
                spy={true}
                smooth={true}
                offset={-75}
                className="hover:text-white duration-700 hover:pl-1"
              >
                Skills
              </Link>
            </li>
            <li className="text-gray-400 leading-7">
              <Link
                href="#services"
                to="services"
                spy={true}
                smooth={true}
                offset={-75}
                className="hover:text-white duration-700 hover:pl-1"
              >
                Services
              </Link>
            </li>
            <li className="text-gray-400 leading-7 ">
              <Link
                href="#contact"
                to="contact"
                spy={true}
                smooth={true}
                offset={-75}
                className="hover:text-white duration-700 hover:pl-1"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="">
          <h3 className="footer-title mb-[10px] relative font-[Rubik] text-xl leading-loose py-[10px]">
            Contacts
          </h3>
          <ul
            className="border-b-2 border-gray-700 pb-[15px]"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="2000"
          >
            <li className="text-gray-400 leading-7 ">
              <a
                href="tel:+212-766210264"
                className="hover:text-white duration-700 hover:pl-1"
              >
                +212-766210264
              </a>
            </li>
            <li className="text-gray-400 leading-7 ">
              <a
                href="mailto:medamrouss14@gmail.com"
                className="hover:text-white duration-700 hover:pl-1"
              >
                medamrouss14@gmail.com
              </a>
            </li>
          </ul>
          <ul
            className="flex gap-5 my-[10px] w-fit pt-[15px]"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="2000"
          >
            <li className="">
              <a
                href="https://www.facebook.com/profile.php?id=100013120950245"
                target="_blank"
                className="p-[2px] text-gray-400 hover:text-white duration-700"
                rel="noreferrer"
              >
                <i class="fa-brands fa-facebook fa-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/adnan.amrouss/"
                target="_blank"
                className="p-[2px] text-gray-400 hover:text-white duration-700"
                rel="noreferrer"
              >
                <i class="fa-brands fa-instagram fa-xl"></i>
              </a>
            </li>

            <li>
              <a
                href="https://www.linkedin.com/in/adnane-amrouss-545687243/"
                target="_blank"
                className="p-[2px] text-gray-400 hover:text-white duration-700"
                rel="noreferrer"
              >
                <i class="fa-brands fa-linkedin-in fa-xl"></i>
              </a>
            </li>
            <li>
              <a
                className="p-[2px] text-gray-400 hover:text-white duration-700"
                href="https://github.com/adnane-amrs"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fa-brands fa-github fa-xl"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="max-md:pb-20">
          <h3 className="footer-title mb-[10px] relative font-[Rubik] text-xl leading-loose py-[10px] ">
            Goals & Vision
          </h3>
          <p
            className="text-gray-400 text-justify max-md:text-xs max-md:leading-normal"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="2000"
          >
            My passion for software development is fueled by the desire to
            create innovative solutions. I enjoy collaborating with others,
            problem-solving, and staying updated with the latest industry trends
            and best practices.
          </p>
        </div>
      </div>
      <div className="w-full max-md:text-xs py-[12px] bg-gradient-to-r from-Color2 to-Color3 text-white font-[Poppins] text-sm">
        <p className="text-center w-fit h-fit m-auto break-words px-10">
          <span class="text-md">&copy; </span> Copyright {currentYear} - All rights reserved - Developed by Adnane
        </p>
      </div>
    </div>
  )
}
