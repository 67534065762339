import { React, useState } from 'react'
import img1 from '../assets/Adnane2-removebg-preview.png'
import { Link } from 'react-scroll'

export default function Navbar() {
  const [menuOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!menuOpen)
  }
  return (
    <div
      id="navbar"
      className="w-full shadow-2xl overflow-hidden md:fixed md:top-0 z-[4] max-md:z-10 flex flex-row justify-around max-md:justify-between px-10 max-md:px-5 bg-slate-900  md:bg-opacity-50 md:backdrop-blur-lg h-[9vh] max-md:h-fit m-auto"
    >
      {/* Logo */}
      <Link
        to="home"
        spy={true}
        smooth={true}
        offset={-75}
        duration={600}
        className="cursor-pointer flex justify-around"
      >
        <img
          href="#home"
          src={img1}
          alt="Adnane Amrouss"
          className="max-md:w-[60px] max-md:h-[60px] "
          
        />
      </Link>

      <div
        id="links"
        className={`md:static max-md:h-[90%] max-md:absolute max-md:top-[9vh] max-md:w-[100%] md:w-auto flex md:items-center max-md:py-20 duration-700 ease-linear max-md:backdrop-blur-2xl transition-all z-[1] ${
          menuOpen ? 'right-0' : 'right-[800px]'
        } `}
      >
        <ul className="md:pl-20 flex md:flex-row flex-col md:gap-[4vw] gap-9 w-full items-center max-md:justify-start max-md:text-base">
          <li className="">
            <Link
              to="home"
              spy={true}
              smooth={true}
              offset={-75}
              duration={600}
              className=" hover:text-gray-500 duration-700"
              href="#home"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={-75}
              duration={600}
              className=" hover:text-gray-500 duration-700"
              href="#about"
            >
              About Me
            </Link>
          </li>
          <li>
            <Link
              to="skills"
              spy={true}
              smooth={true}
              offset={-75}
              duration={600}
              className=" hover:text-gray-500 duration-700"
              href="#skills"
            >
              Skills
            </Link>
          </li>
          <li>
            <Link
              to="services"
              spy={true}
              smooth={true}
              offset={-75}
              duration={600}
              className="hover:text-gray-500 duration-700 "
              href="#services"
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-75}
              duration={600}
              className="hover:text-gray-500 duration-700"
              href="#contact"
            >
              Contact
            </Link>
          </li>

          <ul className="flex items-center justify-center gap-[5vw] md:hidden max-sm:border-t-2 max-md:border-slate-500 pt-5">
            <li className="">
              <a
                className="hover:text-gray-500 duration-700"
                href="https://github.com/adnane-amrs"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fa-brands fa-github fa-xl"></i>
              </a>
            </li>
            <li>
              <a
                className="hover:text-gray-500 duration-700"
                href="https://www.linkedin.com/in/adnane-amrouss-545687243/"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fa-brands fa-linkedin-in fa-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/adnan.amrouss/"
                target="_blank"
                className="hover:text-gray-500 duration-700"
                rel="noreferrer"
              >
                <i class="fa-brands fa-instagram fa-xl"></i>
              </a>
            </li>
          </ul>
        </ul>
      </div>

      {/* Social Links */}

      <ul className="text-white flex items-center justify-center gap-6 max-md:hidden  h-fit my-auto px-[10px]">
        <li className="">
          <a
            className="hover:text-gray-500 duration-700"
            href="https://github.com/adnane-amrs"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa-brands fa-github fa-xl"></i>
          </a>
        </li>
        <li>
          <a
            className="hover:text-gray-500 duration-700"
            href="https://www.linkedin.com/in/adnane-amrouss-545687243/"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa-brands fa-linkedin-in fa-xl"></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/adnan.amrouss/"
            target="_blank"
            className="hover:text-gray-500 duration-700"
            rel="noreferrer"
          >
            <i class="fa-brands fa-instagram fa-xl"></i>
          </a>
        </li>
      </ul>
      <button
        onClick={handleOpen}
        className="
                text-white text-2xl align-center md:hidden h-fit cursor-pointer w-fit my-auto"
      >
        <i
          className={`text-center  ${
            menuOpen ? 'fa-solid fa-xmark fa-lg' : 'fa-solid fa-bars-staggered'
          }
                `}
        ></i>
      </button>
    </div>
  )
}
