import React, { useState, useRef, useEffect } from 'react'
import emailjs from '@emailjs/browser'
import Swal from 'sweetalert2'

export default function EmailForm() {
  const form = useRef()

  const [values, setValues] = useState({
    name: '',
    email: '',
    message: '',
  })
  const [errors, setErrors] = useState({
  })
  const [status, setStatus] = useState('')

  // Validation Function
  const Validation = (values) => {
    const errors = {}
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,6}$/

    if (values.name === '') {
      errors.name = 'Full Name is Required'
    }

    if (values.email === '') {
      errors.email = 'Email is Required '
    } else if (!email_pattern.test(values.email)) {
      errors.email = 'Email is not Correct  '
    }

    if (values.message === '') {
      errors.message = 'Type a Short Message !'
    }

    return errors
  }

  useEffect(() => {
    if (status === 'SUCCESS!') {
      setTimeout(() => {
        setStatus('')
      }, 3000)
    }
  }, [status])

  const handleInput = (event) => {
    const newObj = { ...values, [event.target.name]: event.target.value }
    setValues(newObj)
  }

  const handleValidation = (e) => {
    e.preventDefault()

    if (
      values.name === '' ||
      values.email === '' ||
      values.message === ''
    ) {
      setErrors(Validation(values))
    } else {
      emailjs.send('service_h54ux98', 'template_14aqbvk', values, '-3FLLd9X4hY2ebbB0')
      .then(() =>{
        setValues({
          name: '',
          email: '',
          message: '',
        })
        Swal.fire(
          'Good job!',
          'Your Message Submitted Successefully',
          'success'
        )
        setErrors(!Validation(values))
      })
    }
  }

  // const renderAlert = () => {
  //   return (
  //     <div className="border-t-4 border-gray-950 w-full p-[10px] bg-slate-700 leading-normal duration-700 transition-all text-center">
  //       <p>
  //         Great, Your Message Submitted Successefully <br /> Thank You !
  //       </p>
  //     </div>
  //   )
  // }

  return (
    <div>
      <form
        ref={form}
        className="emailForm flex flex-col p-[40px] max-lg:p-[20px] gap-5 z-[1] max-sm:border-t-2 max-md:border-slate-800"
        onSubmit={handleValidation}
      >
        {/* {status && renderAlert()} */}
        <h1 className="font-[Rubik] text-2xl max-md:text-xl">
          Make First Contact
        </h1>

        <div className="">
          <input
            type="text"
            value={values.name}
            name="name"
            className="w-full p-[12px] outline-none rounded-md bg-gray-950 focus:bg-slate-700 focus:shadow-md duration-500 transition-all"
            placeholder="Full Name"
            onChange={handleInput}
            autoComplete="off"
          />
          {errors.name && (
            <p className="text-red-600 transition-all duration-700">
              {errors.name}
            </p>
          )}
        </div>
        <div>
          <input
            type="email"
            value={values.email}
            name="email"
            className="w-full p-[12px] outline-none rounded-md bg-gray-950 focus:bg-slate-700 focus:shadow-md duration-500 transition-all"
            placeholder="Email"
            onChange={handleInput}
            autoComplete="off"
          />
          {errors.email && (
            <p className="text-red-600 transition-all duration-700">
              {errors.email}
            </p>
          )}
        </div>
        <div>
          <textarea
            name="message"
            value={values.message}
            cols="20"
            rows="7"
            className="w-full p-[12px] outline-none rounded-md bg-gray-950 focus:bg-slate-700 focus:shadow-md duration-500 transition-all"
            placeholder="Your Message ..."
            onChange={handleInput}
            autoComplete="off"
          ></textarea>
          {errors.message && (
            <p className="text-red-600 transition-all duration-700">
              {errors.message}
            </p>
          )}
        </div>

        <input
          type="submit"
          value="CONTACT ME"
          className="max-md:w-full px-10 bg-gradient-to-r from-Color1 via-Color2 to-Color3 rounded-md hover:shadow-md hover:shadow-Color2 transition-all duration-700 delay-100 ease-out py-3 max-md:px-7 cursor-pointer"
        ></input>
      </form>
    </div>
  )
}