import React from 'react'
import {Link} from 'react-scroll'
import { TypeAnimation } from 'react-type-animation'
import Lottie from 'lottie-react'
import skill1 from './developer.json'
import 'aos/dist/aos.css'
import Navbar from '../component/navbar'
import cvPdf from '../assets/Adnane Amrouss.pdf'

export default function Home() {
  return (
    <div
      id="home"
      className="font-[Poppins] h-[100vh] max-lg:pb-10 text-white bg-gray-950 text-sm max-md:text-xs overflow-hidden "
    >
      <Navbar />

      <div
        className="relative z-[0] max-md:hidden"
        data-aos="fade-right"
        data-aos-duration="3000"
      >
        <div id="home1" className="service relative text-center "></div>
      </div>
      <div
        className="relative z-[0] max-md:hidden"
        data-aos="fade-right"
        data-aos-duration="3000"
      >
        <div id="home2" className="service relative text-center "></div>
      </div>

      <div
        id="details"
        className="realtive w-[85%] max-md:w-full flex justify-center items-center max-md:flex-col  align-middle max-md:px-[10px] h-[100vh] m-auto gap-5 max-md:text-center z-[2] max-md:py-0 max-md:h-[90vh]"
      >
        <div
          className="w-1/2 lg:pl-8 max-xl:w-full"
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          <h1 className="text-5xl font-[Rubik] bg-gradient-to-r from-Color1 via-Color2 to-Color3  to-cyan-00 text-transparent bg-clip-text leading max-md:text-3xl">
            Adnane Amrouss
          </h1>
          <div className="font-[Rubik] text-2xl max-md:text-xl">
            <TypeAnimation
              sequence={[
                // Same substring at the start will only be typed out once, initially
                "I'm Web Developer",
                1000, // wait 1s before replacing "Mice" with "Hamsters"
                "I'm Web Designer",
                1000,
                "I'm Mobile Developer",
                1000,
                "I'm Data Science Student ",
                1000,
              ]}
              wrapper="span"
              speed={50}
              style={{
                display: 'inline-block',
                lineHeight: 2,
              }}
              repeat={Infinity}
            />
          </div>
          <p className=" text-gray-400 md:w-[90%]">
            My experience spans web and mobile applications, embedded systems,
            making me versatile and adaptable to your unique needs. 
          </p>
          <p className=" text-gray-400 mt-2 md:w-[90%]">
          Explore my work, and let's connect to discuss how I can help you
            bring your vision to life. Your success is my priority.
          </p>
          <p className="my-3 text-gray-300">
            Let's create the future together.
          </p>
          <div
            className="flex max-lg:justify-center 
          "
          >
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-75}
              duration={600}
              href="#contact"
              id="btn1"
              className="text-center w-[150px] px-[10px] bg-gradient-to-r mr-5 from-Color1 via-Color2 to-Color3 rounded-md hover:shadow-md hover:shadow-Color2 transition-all duration-700 ease-out py-3 max-md:px-7 uppercase"
            >
              Contact Me
            </Link>
            <a
              href={cvPdf}
              download={'Amrouss Adnane'}
              target="_blank"
              rel="noreferrer"
              className="w-[150px] text-center px-[10px] bg-gray-800 hover:bg-slate-600 duration-1000 py-3 rounded-md max-md:px-5 uppercase"
            >
              Resume
            </a>
          </div>
        </div>
        <div
          className="lg:w-1/2 max-md:py-[10px] max-md:mx-auto max-md:w-[330px]"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <Lottie
            className=" "
            onComplete={() => {
              console.log('complete')
            }}
            style={{}}
            animationData={skill1}
          />
        </div>
      </div>
    </div>
  )
}
